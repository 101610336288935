import Image from 'next/image';
import {useRouter} from 'next/router';
import React, {useEffect, useRef, useState} from 'react';

import {Container, Theme, useMediaQuery} from '@mui/material';

import {SubcomponentsTeaserGridSlideComponent} from 'lib/api/strapi';

import {Box, Button, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import styles from './TeaserGrid.module.scss';

interface CarouselProps {
  slides: SubcomponentsTeaserGridSlideComponent[];
  autoPlayInterval?: number;
  getLinkClickHandler?: ({gridComponent, index}: {gridComponent: SubcomponentsTeaserGridSlideComponent, index: number}) => ({sliderPosition}: {sliderPosition: number}) => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  slides,
  autoPlayInterval = 5000,
  getLinkClickHandler = () => () => {},
}) => {

  const filteredSlides = slides.filter(
    slide =>
      (slide.startShowingSlide === null && slide.stopShowingSlide === null) ||
      (new Date() > new Date(slide.startShowingSlide) &&
        new Date() < new Date(slide.stopShowingSlide)),
  );

  const {push} = useRouter();
  const [slideIndex, setSlideIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const imageRef = useRef<HTMLElement>(null);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleSlideChange = (index: number) => {
    setSlideIndex(index);
  };

  useEffect(() => {
    if (slideIndex !== null) {
      containerRef.current.scrollTo({
        left: slideIndex * imageRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [slideIndex]);

  const containerRef = useRef<HTMLElement>();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const nextSlide = () => {
      setSlideIndex(prevIndex => (prevIndex + 1) % filteredSlides.length);
    };

    intervalRef.current = setInterval(nextSlide, autoPlayInterval);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [filteredSlides.length, autoPlayInterval, slideIndex]);

  const handleMouseDown = (containerRef: React.RefObject<HTMLElement>) => (e: React.MouseEvent) => {
    const ele = containerRef.current as HTMLElement;
    setSlideIndex(null);
    if (!ele) {
      return;
    }
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (e: React.MouseEvent) => {
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
      if (dx || dy) {
        setIsDragging(true);
      }
    };

    const handleMouseUp = () => {
      const index = Math.round((ele.scrollLeft || 1) / ele.children[0].children[0].scrollWidth);
      handleSlideChange(index);
      document.removeEventListener('mousemove', handleMouseMove as any);
      document.removeEventListener('mouseup', handleMouseUp);
      resetCursor(ele);
      if (isDragging) {
        e.stopPropagation;
      }
      setTimeout(() => {
        setIsDragging(false);
      }, 50);
    };

    document.addEventListener('mousemove', handleMouseMove as any);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart =
    (containerRef: React.RefObject<HTMLElement>) => (e: React.TouchEvent) => {
      const ele = containerRef.current as any;
      setSlideIndex(null);

      if (!ele) {
        return;
      }
      const touch = e.touches[0];
      const startPos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        x: touch.clientX,
        y: touch.clientY,
      };

      const handleTouchMove = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        ele.scrollTop = startPos.top - dy;
        ele.scrollLeft = startPos.left - dx;
        updateCursor(ele);
        if (dx || dy) {
          setIsDragging(true);
        }
      };

      const handleTouchEnd = () => {
        const index = Math.round((ele.scrollLeft || 1) / ele.children[0].children[0].scrollWidth);
        handleSlideChange(index);
        document.removeEventListener('touchmove', handleTouchMove as any);
        document.removeEventListener('touchend', handleTouchEnd);
        resetCursor(ele);
        if (isDragging) {
          e.stopPropagation;
        }
        setTimeout(() => {
          setIsDragging(false);
        }, 50);
      };

      document.addEventListener('touchmove', handleTouchMove as any);
      document.addEventListener('touchend', handleTouchEnd);
    };

  const updateCursor = (ele: HTMLElement) => {
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';
  };

  const resetCursor = (ele: HTMLElement) => {
    ele.style.cursor = 'pointer';
    ele.style.removeProperty('user-select');
  };

  return (
    <Container className={styles.carouselWrapper}>
      <Box
        className={styles.imageBox}
        ref={containerRef}
        onMouseDown={handleMouseDown(containerRef)}
        onTouchStart={handleTouchStart(containerRef)}
      >
        <Box
          className={styles.imageWrapper}
          sx={{
            height: '100%',
          }}
        >
          {filteredSlides.map((data, index) => {
            const imageSrc = isDesktop
              ? data?.image?.data?.attributes?.url
              : data?.imageMobile?.data?.attributes?.url || data?.image?.data?.attributes?.url;

            const imageHeight = isDesktop
              ? data?.image?.data?.attributes?.height
              : data?.imageMobile?.data?.attributes?.height ||
                data?.image?.data?.attributes?.height;

            const imageWidth = isDesktop
              ? data?.image?.data?.attributes?.width
              : data?.imageMobile?.data?.attributes?.width || data?.image?.data?.attributes?.width;
            return (
              <Box
                key={data.id}
                sx={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  flex: '1 0 auto',
                  transition: 'translate 300ms ease-in-out',
                }}
                onClick={() => {
                  if (!isDragging) {
                    const linkHref = data?.link?.page?.data?.attributes?.fullSlug
                      ? data?.link?.page?.data?.attributes?.fullSlug
                      : data?.link?.link;
                    getLinkClickHandler({index: 0, gridComponent: data})({sliderPosition: index})
                    if (data?.link?.newWindow) {
                      window.open(linkHref, '_blank');
                    } else {
                      push(linkHref);
                    }
                  }
                }}
              >
                <Box
                  className={styles.background}
                  sx={{
                    opacity: '0.5',
                  }}
                />
                <Image
                  ref={(index === 0 ? imageRef : null) as any}
                  src={imageSrc}
                  height={imageHeight}
                  width={imageWidth}
                  alt=""
                  className={styles.imageContainer}
                  draggable={false}
                  sizes="(max-width: 769px) 100vw,  66vw"
                  quality={80}
                />
                <Box className={styles.bigGridItemContentWrapper}>
                  {data?.subtitle ? (
                    <Typography className={styles.subtitle} component={data?.subTitleLevel}>
                      {data?.subtitle}
                    </Typography>
                  ) : null}
                  <Typography className={styles.title} variant="h2" component={data?.titleLevel}>
                    {data?.title}
                  </Typography>
                  <Box className={styles.buttonWrapper}>
                    <Button>{data.link?.linkText}</Button>
                  </Box>
                  <Box className={styles.linkWrapperSlider}>
                    <Typography className={styles.linkText} variant="buttonCards">
                      {data?.link?.linkText}
                    </Typography>
                    <CustomIcon name="arrow" className={styles.icon} />
                  </Box>
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              bottom: {xxs: '20px', md: '40px'},
              right: {xxs: '20px', md: '40px'},
              height: {xxs: '24px', md: '50px'},
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {filteredSlides.map((slide, index) => {
              return (
                <span
                  key={index}
                  style={{
                    height: '12px',
                    width: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    opacity: slideIndex === index ? 1 : 0.7,
                    display: 'block',
                    zIndex: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => setSlideIndex(index)}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
